.modal{
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: .2s;
  z-index: 12;
}
.modal.is-open {
  visibility: visible;
  opacity: 1;
}
.modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  outline: 0;
  overflow-y: scroll;
  z-index: 12;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { 
    display: none;
  }
}

.modal__container{
  width: 510px;
  height: auto;
  position: relative;
  background-color: $white;
  margin: 10rem auto 0;
  padding: 6rem 4.5rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { 
    display: none;
  }
}
.modal__content{
  width: 100%;
  height: 100%;
}
.close-modal{
  position: absolute;
  top: 35px;
  right: 35px;
  .icon-close{
    @include stroke-color($night);
  }
}
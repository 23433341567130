@mixin transition($transition...) {
  transition: $transition;
}

%font-smoothing {
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%transition {
  @include transition(all 400ms cubic-bezier(.445, .5, .55, .95));

}

%transition-slow {
  @include transition(all 800ms cubic-bezier(0.215, 0.61, 0.355, 1));
}

%hover-up {
  will-change: transform;

  &:hover {
    transform: translateY(-3px);
  }
}

%hover-underline {
  will-change: transform;

  &:hover {
    text-decoration: underline;
  }
}

%hover-up {
  will-change: transform;

  &:hover {
    text-decoration: underline;
  }
}

.mx-reset {
  margin: 0;
}

.btn,
button:focus {
  outline   : 0;
  appearance: none;
}

.ul-reset {
  list-style: none;
  padding:0;
  * {
    text-decoration: none !important;
  }
}

input.text,
input[type="text"],
input[type="date"],
input[type="tel"],
input[type="email"],
input[type="button"],
input[type="submit"],
.input-checkbox,
textarea {
  -webkit-appearance: none;
  border-radius     : 0;
}

[class^=col] {
  img {
    max-width: 100%;
  }
}

::placeholder {
  color: $placeholder-color;
}

.h1,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin-bottom: 0;
}


#__bs_notify__ {
  top          : 95% !important;
  right        : 10px !important;
  border-radius: 15px !important;
  height       : 60px !important;
}

a {
  color: inherit;

  &:hover {
    color          : inherit;
    text-decoration: none;

  }
}



.page-splash {
  height: 100vh;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF8F0;
  position: relative;

  .position-relative img {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .tag {
    border-radius:11px;
    background-color: $primary;
    color: white;
    padding: 0 10px;
    display: inline-block;


    &-circle {
      border-radius: 100%;
      background-color: $olive;
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-align: center;
      position: absolute;
      top:-100px;
      z-index: 0;
      -webkit-animation:spin 5s linear infinite;
      -moz-animation:spin 5s linear infinite;
      animation:spin 5s linear infinite;
      backface-visibility: hidden;
      will-change: transform;
    }
  }

  .footer-link {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm){
    min-height: 300px;

    .position-relative.text-center {
      padding: 0 15px;
    }
  }

}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.input-group{
  position: relative;
  margin-bottom: 1.5rem;
  .form-control-label{
    @extend .link;
    position: absolute;
    top: 15px;
    left: 0;
    color: $night;
    transition: .2s;
    pointer-events: none;
  }

  .form-control{
    padding-top: 2.5rem;
    &.active,
    &:focus,
    &:valid{
      & + .form-control-label{
        font-size: 1.1rem;
        line-height: 1.6rem;
        letter-spacing: 0;
        top: 0;
      }
    }
  } 
}
.select-input{
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 10px;
    height: 5px;
    background-image: url('/img/svg/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.form-control{
  color: $night;
  background: transparent;
  border: 0;
  border-bottom: .1rem solid $night;
  border-radius: 0;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus{ outline: 0; }
  &::placeholder{ color: $night; }

  &:-webkit-autofill,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
      transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
}
.was-validated{
  input, textarea{
    &:invalid~.invalid-feedback{
      display: block;
    }
  }
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .5rem;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: .03rem;
  font-weight: normal;
  color: $error;
}

.input-group-inline{
  display: flex;
  border-bottom: .1rem solid $night;
  margin-bottom: 2rem;
  
  input, button{
    background: transparent;
    border: 0;
  }
  input{ 
    color: $night;
    padding: 0;
    &::placeholder{
      color: $night;
    }
  }
}

.form-check-label {
  position: relative;
  padding-left: 18px;
  cursor: pointer;
  margin-bottom: 0;
}
.form-check-input { 
  display: none; 

  &:checked ~ .checkmark {
    background-image: url('/img/svg/icons/checkbox-checked.svg');
  }
  &:checked ~ .radiomark {
    background-image: url('/img/svg/icons/radio-checked.svg');
  }
}
.checkmark,
.radiomark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.checkmark { background-image: url('/img/svg/icons/checkbox.svg'); }
.radiomark { background-image: url('/img/svg/icons/radio.svg');}
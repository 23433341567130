.loader-component {
  width          : 100%;
  height         : 100%;
  position       : fixed;
  background     : $night;
  z-index        : 2090;
  display        : flex;
  align-items    : center;
  justify-content: center;
  overflow       : hidden;
}

html{
  font-size: 62.5%;
  @extend %font-smoothing;
  scroll-behavior: smooth;
  background-color: $body-background-color;
}
body{
  @extend %type-body;
  @extend %font-smoothing;
  background: $body-background-color;
  color: $body-text-color;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing:normal;
}

main {
  opacity: 0;
}
.body-no-scroll{
  overflow: hidden;
}
a:not([href]):not([tabindex]){
  cursor: pointer;
}

.bg-olive {
  background-color: $olive;
}

@include header-variant('black', $night);
@include header-variant('white', $white);

@include media-breakpoint-down(lg){
  .container{
    max-width: 1080px;
  }
}